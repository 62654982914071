

// .sand-clock {
//     animation: rotate-sand-clock 3s linear infinite;
// }

// @keyframes rotate-sand-clock {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(360deg);
//     }
// }

// @mixin animate($animation,$duration,$method,$times){
//     animation: $animation $duration $method $times;
// }

// @mixin keyframes($name){
//     @keyframes #{$name}{
//         @content;
//     }
// }

// @include keyframes(rotate-sand-clock){
//     0% {
//             transform: rotate(0deg);
//         }
    
//         100% {
//             transform: rotate(360deg);
//         }
// }

// .sand-clock{   
//     @include animate(rotate-sand-clock, 3s, linear, infinite )
// } 


@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }  
    @-ms-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
      @content;
    }  
    @keyframes #{$animation-name} {
      @content;
    }
  }
  
  @mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
  }
  
  @include keyframes(rotate-sand-clock) {
    // 0% { opacity: 1; }
    // 90% { opacity: 0; }
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }

  .sandClockContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .sandClock {
          @include animation('rotate-sand-clock 3s linear infinite');
      }
  }
  
  