.viewAllContractButton {    
    border-radius: 4px;
    border: 1px solid #321149;
    background: #FFF;
    box-shadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)"
}

.contractNumberStyle {
    color: #1F1F1F;
    font-family: Aptos;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right:10px !important;
}
