
.headerWidth  {

  text-align: center !important;
}

.approveSec {
    //  width: 100%;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
     background-color: #129507;
     color: white !important;
}

.rejectedSec {
    // width: 100%;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background-color: #D70000;
    color: white !important;
}