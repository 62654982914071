$primary-color: var(--primary-color, #007bff);
$themeMode_background: var(--themeMode_background, #ffff);

.tab_outer {
  border: 1px solid #d9d9d9;
  overflow: auto;
  max-height: calc(100vh - 150px) !important;
 
  
}

.tab_header {
  text-align: center !important;
  padding: 12px !important;
  // padding:'0.75rem 1rem 0.6rem !important' ;
  font-weight: 800 !important;
  font-size: 12px;
  // border: 1px solid #f7f7f7;
  position: sticky !important;
  top: 0 !important ; // Fixed the header to the top
  z-index: 2 !important;
  min-width : "24%";
  white-space: "pre" ;
  background-color: $themeMode_background;
  border:  1px solid rgb(235,235,235) ;
  // border-bottom: 1px solid rgb(235,235,235) !important;
}

.tab_data {
  // padding: 6px !important;
  padding:'0.75rem 1rem 0.6rem !important' ;
  // border: 1px solid #f7f7f7;
}

.alternateRow {
  border-bottom: 1px solid #6a6a6a;
}

.alternateRow:nth-child(even) {
  background-color: #eaeaea;
}

.alternateRowBg {
  background-color: #eaeaea;
}

.tab_header_plain {
  padding: 6px;
  font-weight: 800;
  font-size: 12px;
}

.tab_data_plain {
  padding: 6px;
}
