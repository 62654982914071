$primary-color: var(--primary-color, #007bff);
$themeMode_background: var(--themeMode_background, #ffff);

.accordion {
    margin: 0.5rem 0; // Adjust margin as needed
  }
  
  .summary {
    display: flex;
    align-items: center;
    background-color:$themeMode_background ;
  }
  
  .dot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background-color: $primary-color; // Color for the dot, change as needed
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    position: relative;
  }

  .innerdot {

    height: 8px;
    width: 8px;
    background-color: #FDB913; // Color for the dot, change as needed
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 4px;
  }
  
  .title {
    // width: 33%;
    flex-shrink: 0;
    text-align: left;
  }
  
  .secondaryText {
    color: black;
  }
  
  .details {
    text-align: left;
  }
  