$primary-color: var(--primary-color, #007bff);
$themeMode_background: var(--themeMode_background, #ffff);



.sliding_border {
    width: 70%;
    height: 100vh;
    background-color:  $primary-color;
    position: relative;
   
  }

  .sliding_border::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 20vw;
    height: 100%;
    background: linear-gradient(to bottom right, transparent 50%, $themeMode_background 10%);
  }