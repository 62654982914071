.iconButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.125rem;
  height: 2rem;
  width: 2rem;
  margin-left: 10px;
  opacity: 0.5;
  transition: opacity 150ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: visible;
}
