.gridCss{
    >div>div:first-child{
        display: none;
    }
    >div>div:nth-child(3){
        display: none;
    }
}

.helperTextError{
    color: rgb(215, 0, 0);
    font-family: aptos;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin: 4px 14px 0px;
}