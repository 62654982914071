$primary-color: var(--primary-color, #007bff);
$themeMode_background: var(--themeMode_background, #ffff);


.tab_outer {
   
    border: 1px solid #D9D9D9;
   
  }

  .tab_header {
   
//    padding:  0px 8px 0px 16px;
   padding: 6px;
   font-weight: 800;
   font-size: 12px;
   border: 1px solid #F7F7F7;
//    line-height: 16px;
  }

  .tab_data {
   
    padding: 6px;
    border: 1px solid #F7F7F7;
    
   }

   .questionRow {
      background-color: $themeMode_background;
   }

