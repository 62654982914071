$primary-color: var(--primary-color, #007bff);
$themeMode_background: var(--themeMode_background, #ffff);

.breadcrumb {
  display: inline-block;
  // overflow: hidden;
  // border-radius: 5px;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 28px;
  color: #747474;
  padding: 0 10px 0 30px;
  background: linear-gradient(#b71c1c, #333);
  position: relative;
  cursor: pointer;
}
.breadcrumb a:first-child {
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
}

.breadcrumb a:last-child {
  color: #202020;
}

/*hover/active styles*/
// .breadcrumb a.active, .breadcrumb a:hover{
// 	background: #bf3e3e;
// 	background: linear-gradient(#f6f6f6, #f0efef);
// }
// .breadcrumb a.active:after, .breadcrumb a:hover:after {
// 	background: #333;
// 	background: linear-gradient(135deg, #f6f6f6, #f0efef);
// }

.breadcrumb a:after {
  content: "";
  position: absolute;
  top: 0;
  width: 28px;
  height: 28px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  box-shadow: 2px -2px 0 2px $themeMode_background ;
  border-radius: 0 5px 0 50px;
}

.breadcrumb a:last-child:after {
  // content: none;
  position: absolute;
  right: -15px;
}

.flat a,
.flat a:after {
  background: #d9d9d9;
  color: 747474;
  transition: all 0.5s;
  font-size: 12px;
}
.flat a:before {
  background: $themeMode_background;
  box-shadow: 0 0 0 1px $themeMode_background;
}
